import moment from "moment";

export const modelAiOptions = [
  {
    value: "gemini-2.0-flash-exp",
    label: "gemini-2.0-flash-exp",
  },
  {
    value: "gemini-1.5-flash",
    label: "gemini-1.5-flash",
  },
  {
    value: "gemini-1.5-flash-8b",
    label: "gemini-1.5-flash-8b",
  },
  {
    value: "gemini-1.5-pro",
    label: "gemini-1.5-pro",
  },
  {
    value: "text-embedding-004",
    label: "text-embedding-004",
  },
  {
    value: "aqa",
    label: "aqa",
  },
];

export enum instructionType {
  CheckIn,
  OneToOne,
  Conference,
  CheckInAndOneToOne,
}

export const instructionOptions = [
  {
    value: instructionType.CheckIn,
    label: "Check In",
  },
  {
    value: instructionType.OneToOne,
    label: "One to One",
  }
];

export enum MessageRole {
  System,
  User,
  Model,
}

export enum FILTER_NAME {
  Grade = "Grade",
  StartDate = "Start Date",
  EndDate = "End Date",
  Calendar = "Calendar",
}

export const CALENDAR_OPTIONS = [
  {
    label: "Today",
    startDate: moment().startOf("day").valueOf(),
    endDate: moment().endOf("day").valueOf(),
    value: "Today",
  },
  {
    label: "This week",
    startDate: moment().startOf("week").weekday(1).valueOf(),
    endDate: moment().endOf("week").weekday(7).valueOf(),
    value: "Week",
  },
  {
    label: "This month",
    startDate: moment().startOf("month").valueOf(),
    endDate: moment().endOf("month").valueOf(),
    value: "Month",
  },
];

export const GRADE_DEFAULT: string[] = [
  "All",
  "K",
  "G",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const DEFAULT_START_DATE = moment()
  .subtract(1, "months")
  .startOf("day")
  .valueOf();
export const DEFAULT_END_DATE = moment().endOf("day").valueOf();

export const sampleDataSession = [
  { studentName: "Kriness", grade: 10, question: "Stress", answer: "20%" },
  { studentName: "Yellsin", grade: 10, question: "Safety", answer: "80%" },
  { studentName: "Nana", grade: 10, question: "well-being", answer: "20%" },
  {
    studentName: "Saru",
    grade: 10,
    question: "Are you ready to learn?",
    answer: "Yes",
  },
  {
    studentName: "Lina",
    grade: 10,
    question: "What is on your mind?",
    answer: "Feeling overwhelmed with homework.",
  },

  { studentName: "John", grade: 8, question: "Energy", answer: "50%" },
  { studentName: "Emma", grade: 7, question: "Focus", answer: "70%" },
  { studentName: "David", grade: 9, question: "Happiness", answer: "90%" },
  { studentName: "Sophia", grade: 11, question: "Growth", answer: "40%" },
  { studentName: "James", grade: 6, question: "Relationships", answer: "60%" },

  { studentName: "Lily", grade: 12, question: "Productivity", answer: "75%" },
  { studentName: "Ethan", grade: 8, question: "Academics", answer: "85%" },
  { studentName: "Olivia", grade: 9, question: "Self-efficacy", answer: "45%" },
  {
    studentName: "Mason",
    grade: 7,
    question: "Are you ready to learn?",
    answer: "No",
  },
  {
    studentName: "Charlotte",
    grade: 11,
    question: "What is on your mind?",
    answer: "Excited for the school trip!",
  },

  { studentName: "Aiden", grade: 6, question: "Stress", answer: "30%" },
  { studentName: "Harper", grade: 7, question: "Safety", answer: "90%" },
  { studentName: "Benjamin", grade: 8, question: "well-being", answer: "50%" },
  { studentName: "Evelyn", grade: 9, question: "Energy", answer: "60%" },
  { studentName: "Lucas", grade: 10, question: "Focus", answer: "80%" },

  { studentName: "Ella", grade: 11, question: "Happiness", answer: "95%" },
  { studentName: "Henry", grade: 12, question: "Growth", answer: "55%" },
  {
    studentName: "Scarlett",
    grade: 6,
    question: "Relationships",
    answer: "70%",
  },
  { studentName: "Daniel", grade: 7, question: "Productivity", answer: "65%" },
  { studentName: "Grace", grade: 8, question: "Academics", answer: "78%" },

  { studentName: "Liam", grade: 9, question: "Self-efficacy", answer: "35%" },
  {
    studentName: "Zoe",
    grade: 10,
    question: "Are you ready to learn?",
    answer: "Yes",
  },
  {
    studentName: "Chloe",
    grade: 11,
    question: "What is on your mind?",
    answer: "Worried about the upcoming exams.",
  },
  { studentName: "Jack", grade: 12, question: "Stress", answer: "10%" },
  { studentName: "Mia", grade: 6, question: "Safety", answer: "95%" },
];
